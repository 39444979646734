// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

import I18n from 'i18n-js'
window.I18n = I18n

Rails.start()
Turbolinks.start()
ActiveStorage.start()

window.Stimulus = Application.start()
const context = require.context("../controllers", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))

require("../custom/jq_plugins");
require("../custom/loading");
require("../custom/modal");
require("../custom/image_upload_preview");
require('../custom/utilities');
require('../custom/notifications');
require("../custom/confirmation_dialog");
require("../custom/remote_links_fixer");
require("../custom/server_notice");
require("../custom/report_sections");
require("../custom/report_images_upload");
require("../custom/unpublished_notice");

//Require needed vendor libraries.
require('../custom/vendors/modernizr');
require('../custom/vendors/jquery-validate');

//Require partial components.
//require('../custom/components/svg');
require('../custom/components/header');
require('../custom/components/sections');
require('../custom/components/overlay-menu');
require('../custom/components/page-overlay');
require('../custom/components/form');

require('../custom/components/banner');

require('../custom/components/block-slider');
require('../custom/components/custom-video');




