import { Controller } from "@hotwired/stimulus"

export default class ToggleableController extends Controller {
  static targets = ["button", "content"]

  connect() {
    this._init();
  }

  _init() {
    this.contentTarget.classList.add('is-hidden');

    console.log(this.contentTarget)

    this.buttonTarget.addEventListener('click', () => {
      this.contentTarget.classList.toggle('is-hidden');
      this.buttonTarget.classList.toggle('is-active');
    });
  }

}
