import { Controller } from "@hotwired/stimulus"

export default class AutosaveSubsectionsController extends Controller {
  connect() {
    this._init();
  }

  _init() {
    const that = this;
    const element = this.element;
    const subsectionId = this.element.getAttribute('data-subsection');
    const changer = this.element.getAttribute('data-changer');
    let typingTimer;

    element.querySelectorAll('textarea').forEach(input => {
      input.addEventListener("input", () => {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(function(){
          that.saveSubsection(subsectionId, changer);
        }, 1000);
      });
    });

    element.querySelectorAll('input[type="radio"]').forEach(input => {
      input.addEventListener("change", () => {
        that.saveSubsection(subsectionId, changer);
      })
    })

    if (isDevEnvironment()) {
      console.info(`[DEV] Autosave Subsection Initialized`);
    }
  }

  saveSubsection(subsectionId, changer){
    $('.autosaving').removeClass('is-fadedout');
    let subsectionWrapper = document.querySelector(`[data-subsection="${subsectionId}"]`);
    let admin_comment = "";
    let assessor_comment = "";
    let answer = "";
    if($(subsectionWrapper).data('type') == 'text'){
      answer = $(subsectionWrapper).find('textarea').val();
      admin_comment = $(subsectionWrapper).find('textarea[data-comment="admin"]').val();
    } else {
      answer = $(subsectionWrapper).find('input[type="radio"]:checked').val();
      assessor_comment = $(subsectionWrapper).find('textarea[data-comment="assessor"]').val();
      admin_comment = $(subsectionWrapper).find('textarea[data-comment="admin"]').val();
    }

    $.ajax({
      method: "PATCH",
      url: `/${changer}/autosave/subsection/${subsectionId}`,
      dataType: "script",
      data: {
        change: {
          assessment_report_id: $('[data-assessment-report-id]').attr('data-assessment-report-id'),
          last_form_instance_at: $('[data-last-form-instance-at]').attr('data-last-form-instance-at')
        },
        report_subsection: {
          answer: answer,
          assessor_comment: assessor_comment,
          admin_comment: admin_comment
        }
      },
      error: function() {
        dispatchModal(somethingWrongModal, { size: 'small' });
      }
    })
  }
}
