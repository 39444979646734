import { Controller } from "@hotwired/stimulus";

export default class RecordAudioController extends Controller {
  static targets = ["startButton", "stopButton", "pauseButton", "recordingTime", "audioFile"];
  mediaRecorder;
  recordedChunks = [];
  isRecording = false;
  isPaused = false;
  subsectionId;
  animationFrameId;
  pausedDuration = 0;
  lastPauseTime = 0;

  connect() {
    this.subsectionId = this.element.dataset.subsection;
    this.changer = this.element.dataset.changer;
  }

  startRecording() {
    this.element.classList.add('is-loading');
    this.recordShortSample()
      .then((sampleBlob) => this.checkAudioSample(sampleBlob))
      .then((response) => {
        return response.json().then((data) => {
          if (response.ok) {
            this.initiateFullRecording();
          } else {
            dispatchAlertToast(data.errors);
          }
        });
      })
      .catch((error) => {
        dispatchAlertToast(I18n.t('message.something_wrong'));
      }).finally(() => {
        this.element.classList.remove('is-loading');
      });
  }

  recordShortSample() {
    return new Promise((resolve, reject) => {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then((stream) => {
          const sampleRecorder = new MediaRecorder(stream);
          const sampleChunks = [];
          sampleRecorder.ondataavailable = (e) => {
            sampleChunks.push(e.data);
          };
          sampleRecorder.onstop = () => {
            const audioBlob = new Blob(sampleChunks, { type: 'audio/wav' });
            resolve(audioBlob);
            stream.getTracks().forEach(track => track.stop());
          };
          sampleRecorder.start();
          setTimeout(() => {
            sampleRecorder.stop();
          }, 1000); // Record for 1 second
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  checkAudioSample(audioBlob) {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = (event) => {
        const audioBase64 = event.target.result;
        const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        const requestData = {
          audio_clip: audioBase64
        };
        fetch('/check-audio', {
          method: 'POST',
          headers: {
            'X-CSRF-Token': token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestData)
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
      };
      reader.readAsDataURL(audioBlob);
    });
  }

  initiateFullRecording() {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        this.mediaRecorder = new MediaRecorder(stream);
        this.recordedChunks = [];
        this.mediaRecorder.startTime = Date.now();
        this.mediaRecorder.ondataavailable = (e) => {
          this.recordedChunks.push(e.data);
        };
        this.mediaRecorder.onstop = () => {
          const audioBlob = new Blob(this.recordedChunks, { type: 'audio/wav' });
          this.sendAudioData(audioBlob);
          this.updateRecordingTime();
          cancelAnimationFrame(this.animationFrameId);
        };
        this.mediaRecorder.start();
        this.isRecording = true;
        this.startButtonTarget.disabled = true;
        this.pauseButtonTarget.disabled = false;
        this.stopButtonTarget.disabled = false;
        this.updateRecordingTime();
        this.element.classList.add('is-recording');
      })
      .catch((err) => {
        console.error('Error accessing the microphone: ' + err);
      });
  }

  pauseRecording() {
    if (this.isRecording) {
      if (!this.isPaused) {
        this.mediaRecorder.pause();
        this.isPaused = true;
        this.lastPauseTime = Date.now();
        this.pauseButtonTarget.querySelector('[data-status="play"]').style.display = 'block';
        this.pauseButtonTarget.querySelector('[data-status="pause"]').style.display = 'none';
        cancelAnimationFrame(this.animationFrameId);
        this.element.classList.remove('is-recording');
      } else {
        this.mediaRecorder.resume();
        this.isPaused = false;
        this.pausedDuration += Date.now() - this.lastPauseTime;
        this.pauseButtonTarget.querySelector('[data-status="play"]').style.display = 'none';
        this.pauseButtonTarget.querySelector('[data-status="pause"]').style.display = 'block';
        this.updateRecordingTime();
        this.element.classList.add('is-recording');
      }
    }
  }

  stopRecording() {
    this.mediaRecorder.stop();
    this.isRecording = false;
    this.isPaused = false;
    this.pausedDuration = 0;
    this.pauseButtonTarget.querySelector('[data-status="play"]').style.display = 'none';
    this.pauseButtonTarget.querySelector('[data-status="pause"]').style.display = 'block';
    this.startButtonTarget.disabled = false;
    this.pauseButtonTarget.disabled = true;
    this.stopButtonTarget.disabled = true;
    if (this.mediaRecorder.stream) {
      this.mediaRecorder.stream.getTracks().forEach(track => track.stop());
    }
    this.recordingTimeTarget.textContent = "00:00";
    cancelAnimationFrame(this.animationFrameId);
    this.element.classList.remove('is-recording');
  }

  sendAudioData(audioBlob) {
    this.element.classList.add('is-loading');
    const reader = new FileReader();
    reader.onload = (event) => {
      const audioBase64 = event.target.result;
      const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      const reportIdElement = document.querySelector('[data-assessment-report-id]');
      const lastFormInstanceElement = document.querySelector('[data-last-form-instance-at]');
      if (!reportIdElement || !lastFormInstanceElement) {
        console.error('Unable to find elements with data attributes');
        return;
      }
      const reportId = reportIdElement.dataset.assessmentReportId;
      const lastFormInstance = lastFormInstanceElement.dataset.lastFormInstanceAt;
      const requestData = {
        change: {
          assessment_report_id: reportId,
          last_form_instance_at: lastFormInstance
        },
        report_audio_clip: {
          audio_clip: audioBase64
        }
      };
      fetch(`/${this.changer}/autosave/add-audio/${this.subsectionId}`, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to upload audio');
        }
        return response.text();
      })
      .then(scriptText => {
        console.log(scriptText);
        eval(scriptText);
      })
      .catch(error => {
        eval(error);
      }).finally(() => {
        this.element.classList.remove('is-loading');
      });
    };
    reader.readAsDataURL(audioBlob);
  }

  updateRecordingTime() {
    const update = () => {
      const currentTime = Date.now();
      const elapsedMilliseconds = currentTime - this.mediaRecorder.startTime - this.pausedDuration;
      const seconds = Math.floor(elapsedMilliseconds / 1000);
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      this.recordingTimeTarget.textContent = `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
      this.animationFrameId = requestAnimationFrame(update);
    };
    this.animationFrameId = requestAnimationFrame(update);
  }

  uploadFile(event) {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      this.sendAudioData(file);
    }
  }
}
