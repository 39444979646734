import { Controller } from "@hotwired/stimulus"

import * as pdflib from 'pdfjs-dist/legacy/build/pdf.js'

export default class PdfController extends Controller {
  connect() {
    this._init();
  }

  _init() {
    pdflib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.11.338/pdf.worker.js';
    const container = this.element;
    const url = container.dataset.url;

    try {
      pdflib.getDocument(url).promise.then(function (pdfDoc) {
        const totalPages = pdfDoc.numPages;
        const renderPage = function (pageNumber) {
          pdfDoc.getPage(pageNumber).then(function (page) {
            const scale = 3.5;
            const viewport = page.getViewport({ scale });

            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const renderContext = {
              canvasContext: context,
              viewport: viewport
            };

            page.render(renderContext).promise.then(function () {
              container.appendChild(canvas);
              if (pageNumber < totalPages) {
                renderPage(pageNumber + 1); // Render the next page
              }
            });
          });
        };

        renderPage(1); // Start rendering from the first page
      });
    } catch (error) {
      console.log(error)
    }
  }
}

