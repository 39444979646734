import { Controller } from "@hotwired/stimulus";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

export default class DonutChartController extends Controller {
  connect() {
    this._init();
  }

  _init() {
    // Get the chart element
    let chartElement = this.element;

    // Parse the data from the data attributes
    let labels = JSON.parse(chartElement.dataset.labels);
    let data = JSON.parse(chartElement.dataset.data);
    let chartType = chartElement.dataset.type;

    // Helper function to calculate percentage
    const calculatePercentage = (value, total) => ((value / total) * 100).toFixed(2) + "%";

    // Create the chart
    let ctx = chartElement.getContext("2d");
    let chart = new Chart(ctx, {
      type: chartType,
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 1,
          },
        ],
      },
      plugins: [ChartDataLabels],
      options: {
        responsive: true,
        aspectRatio: 2,
        plugins: {
          datalabels: {
            formatter: function(value, context) {
              let total = context.dataset.data.reduce((a, b) => a + b, 0);
              let percentage = calculatePercentage(value, total);
              // Hide label if percentage is less than 5%
              if (value / total < 0.05) {
                return null;
              }
              return percentage;
            }
          },
          legend: {
            padding: 20,
            position: "right",
            labels: {
              generateLabels: function (chart) {
                var data = chart.data;
                if (data.labels.length && data.datasets.length) {
                  var total = data.datasets[0].data.reduce((a, b) => a + b, 0);
                  return data.labels.map((label, i) => {
                    var value = data.datasets[0].data[i];
                    var percentage = calculatePercentage(value, total);
                    return {
                      text: `${label} [${percentage}]`,
                      fillStyle: data.datasets[0].backgroundColor[i],
                      strokeStyle: data.datasets[0].borderColor[i],
                      lineWidth: 1,
                      hidden: isNaN(data.datasets[0].data[i]),
                      index: i,
                    };
                  });
                } else {
                  return [];
                }
              },
            },
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                var label = context.label || "";
                if (label) {
                  label += ": ";
                }
                if (context.parsed) {
                  let total = context.dataset.data.reduce((a, b) => a + b, 0);
                  let percentage = calculatePercentage(context.parsed, total);
                  label += percentage;
                }
                return label;
              },
            },
          },
        },
      },
    });
  }
}
