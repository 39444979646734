window.initSectionsSelect = function(url) {
	$('[data-section-select]').on('change', function(){
		let params = new URLSearchParams();
		params.append(this.name, this.value);

    $.ajax({
      method: "GET",
      url: `${url}?${params}`,
      dataType: "script",
      beforeSend: loadingSection()
    })
	})

	function loadingSection(){
    $('#report-form').find('form').addClass('is-loading');
    $('#report-section').find('[data-section-id]').addClass('is-loading');
	}
}

window.highlightRefuted = function(refuted){
	$('.is-highlighted').removeClass('is-highlighted');
	$(`[data-refutable="${refuted}"]`).addClass('is-highlighted');
}

window.highlightDeletable = function(){
	$('[data-delete]').hover(
    function(){
      $('[data-refutable]').removeClass('is-deleting');
      $(`[data-refutable="${$(this).attr('data-delete')}"]`).addClass('is-deleting');
    },
    function(){
      $('[data-refutable]').removeClass('is-deleting');
    }
  )
}