$(function() {
  if($('.vid-slider').length) {
    var videoSection = $(".video-text-section");
    $('.vid-slider .vid').on('click', function() {
      //console.log("test");
      
      // get required DOM Elements
      var iframe_src = $(this).children('iframe').attr('src'),
      iframe = $(this).find(".iframe-wrapper"),
      iframe_video = iframe.find('iframe'),
      iframe_src = iframe_src + '?autoplay=1&rel=0', // for autoplaying the popup video
      thumb = $(this).find(".video-thumb-img");
      
      // change the video source with the clicked one
      iframe_video.attr('src', iframe_src);
      iframe.fadeIn().addClass('show-video');
      thumb.addClass("hide");

    });

  }

  
});