import { Controller } from "@hotwired/stimulus"

export default class AutoscrollController extends Controller {
  connect() {
    this._init();
  }

  _init() {
    // scroll this.element to bottom
    this.element.scrollTop = this.element.scrollHeight;
  }
}
