import { Controller } from '@hotwired/stimulus'
import tippy from 'tippy.js';

export default class CopyToClipboardController extends Controller {
  // Define the click event action
  copyLink(event) {
    event.preventDefault()

    const link = event.currentTarget
    const linkValue = link.dataset.link
    
    // Create a temporary input element
    const tempInput = document.createElement('input')
    tempInput.style.position = 'absolute'
    tempInput.style.left = '-9999px'
    tempInput.value = linkValue
    
    // Append the input element to the body
    document.body.appendChild(tempInput)
    
    // Copy the value to the clipboard
    tempInput.select()
    document.execCommand('copy')
    
    // Remove the temporary input element
    document.body.removeChild(tempInput)
    
    // Optionally, you can display a success message or perform any other action
    dispatchInfoToast(I18n.t('copied_to_clipboard'), 'bottomCenter')
  }
  
  // Connect the controller
  connect() {
    this.element.addEventListener('click', this.copyLink.bind(this));

    if($(this.element).attr('data-tooltip')){
      tippy([this.element], {
        placement: 'top',
        animation: 'scale-subtle',
        content: (reference) => reference.getAttribute('data-tooltip')
      })
    }

    console.info(`[DEV] Copy To Clipboard initialized for: `, this.element);
  }
  
  // Disconnect the controller
  disconnect() {
    this.element.removeEventListener('click', this.copyLink.bind(this))
  }
}