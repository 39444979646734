import { Controller } from "@hotwired/stimulus";

export default class BurgerController extends Controller {
  connect() {
    this._init();
  }

  _init() {
    const element = this.element;
    element.addEventListener('toggle', () => {
      document.querySelector('body').classList.toggle('burger-is-open', element.open);
    })
  }
}
