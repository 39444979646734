import { Controller } from "@hotwired/stimulus"

export default class ApplicationQuestionFormsController extends Controller {
  connect() {
    this._init();
  }

  _init() {
    const that = this;
    const element = this.element;
    let typingTimer;

    element.querySelectorAll('textarea').forEach(input => {
      input.addEventListener("input", () => {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(function(){
          that.answerQuestion();
        }, 200);
      });
    });

    element.querySelectorAll('input[type="radio"]').forEach(input => {
      input.addEventListener("change", () => {
        that.answerQuestion();
      })
    })

    if (isDevEnvironment()) {
      console.info(`[DEV] Application Question Form Initialized`);
    }
  }

  answerQuestion(answer){
    $.ajax({
      method: "PATCH",
      url: $(this.element).attr('action'),
      dataType: "script",
      data: $(this.element).serialize()
    })
  }
}