
$(document).on('turbolinks:load', function(){ 

    var debounce = require('../utils/debounce');
    
    var $html = $('html');
    var $header = $('.header');
    var $burger = $header.find('.header__menu-trigger .burger');

    $burger.on('click', function(){
        // console.log('am i being triggered?');
    });

    var scrollHandler = debounce(
        function fixateHeader() {
            var top = $(window).scrollTop();

            if(top > 0) {
                $header.addClass('fixing');

                setTimeout(function() {
                    $header
                        .removeClass('fixing')
                        .addClass('fixed');
                }, 200);

            } else {

                if($header.hasClass('fixed')) {
                    $header.addClass('unfixing');

                    setTimeout(function() {
                        $header
                            .removeClass('unfixing')
                            .removeClass('fixed');
                    }, 200);
                }
            }
        }, 200
    );

    scrollHandler();

    $(window).on('scroll', scrollHandler);
    
});
