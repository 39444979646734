import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'
import 'slick-carousel'
import 'slick-carousel/slick/slick.css'

export default class SlickController extends Controller {
  connect() {
    this._init();
  }

  _init() {
    var $section = $(this.element);
    var $slideshow = $section.find('.slider__list');

    $slideshow.slick({
      autoplay: true,
      autoplaySpeed: 1500,
      arrows: true,
      adaptiveHeight: true,
      dots: false,
      slidesToShow: 5,
      slidesToScroll: 1,
      prevArrow: `<img class='prev slick-prev' src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiNmZmYiIHdpZHRoPSIxMS43ODIiIGhlaWdodD0iMjMuNTcxIiB2aWV3Qm94PSIwIDAgMTEuNzgyIDIzLjU3MSI+CjxwYXRoIGlkPSJhcnJvdy1pb3MtYmFjay1vdXRsaW5lIiBkPSJNMjEuODE2LDMxLjA2OWExLjY4NCwxLjY4NCwwLDAsMS0xLjMxMy0uNjIzbC04LjEzMS0xMC4xYTEuNjg0LDEuNjg0LDAsMCwxLDAtMi4xMzhsOC40MTgtMTAuMWExLjY4NiwxLjY4NiwwLDEsMSwyLjU5MywyLjE1NWwtNy41MjUsOS4wMjQsNy4yNzMsOS4wMjRhMS42ODQsMS42ODQsMCwwLDEtMS4zMTMsMi43NjFaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTEuOTg5IC03LjQ5OCkiLz4KPC9zdmc+'>"
            `,
      nextArrow: "<img class='next slick-next' src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiNmZmYiIHdpZHRoPSIxMS43ODIiIGhlaWdodD0iMjMuNTcxIiB2aWV3Qm94PSIwIDAgMTEuNzgyIDIzLjU3MSI+CiAgPHBhdGggaWQ9ImFycm93LWlvcy1iYWNrLW91dGxpbmUiIGQ9Ik0yMS44MTYsMzEuMDY5YTEuNjg0LDEuNjg0LDAsMCwxLTEuMzEzLS42MjNsLTguMTMxLTEwLjFhMS42ODQsMS42ODQsMCwwLDEsMC0yLjEzOGw4LjQxOC0xMC4xYTEuNjg2LDEuNjg2LDAsMSwxLDIuNTkzLDIuMTU1bC03LjUyNSw5LjAyNCw3LjI3Myw5LjAyNGExLjY4NCwxLjY4NCwwLDAsMS0xLjMxMywyLjc2MVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIzLjc3MSAzMS4wNjkpIHJvdGF0ZSgxODApIi8+Cjwvc3ZnPg=='>",
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            //slidesToScroll: 3,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            //slidesToScroll: 2
          }
        },
        {
          breakpoint: 580,
          settings: {
            slidesToShow: 1,
            //slidesToScroll: 1
          }
        }
      ]
    });
    console.info('Slick initialized');
  }
}
