import { Controller } from "@hotwired/stimulus"

export default class GrammarlyDetectorController extends Controller {
    #grammarlyPresent = false;
    #notificationShown = false;

    connect() {
        if(!this.detectGrammarly()) return;

        console.info('[DEV] GrammarlyDetectorController connected');
        setTimeout(() => {
            this.showGrammarlyWarning();
        }, 3000);
    }

    showGrammarlyWarning(event) {
        this.#checkForGrammarly();
        if (!this.#grammarlyPresent && !this.#notificationShown) {
            window.dispatchModal(this.#modal(), { size: 'small', prevent_close: true });
            document.getElementById('continue-without-spellcheck').addEventListener('click', () => {
                this.#notificationShown = true;
                window.closeModal();
            });
        }
    }

    #checkForGrammarly() {
        if (window.document.getElementsByTagName('grammarly-desktop-integration').length > 0) {
            this.#grammarlyPresent = true;
            console.info('[DEV] Grammarly detected');
        }
    }

    #modal() {
        const wrapper = document.createElement('div');
        wrapper.innerHTML = `
        <h2>${I18n.t("grammarly.not_present.title")}</h2>
        <p>${I18n.t("grammarly.not_present.message")}</p>
        <p>${I18n.t("grammarly.not_present.download_instructions")}</p>
        <div class="flex flex-justify-end">
            <button id='continue-without-spellcheck' class='button is-ghost'>${I18n.t("grammarly.not_present.ignore_button")}</button>
            <a class='button' href='https://www.grammarly.com/browser' target='_blank'>${I18n.t("grammarly.not_present.install_button")}</a>
        </div>
        `;
        return wrapper.outerHTML;
    }

    detectGrammarly() {
        return document.body.getAttribute('data-detect-grammarly') === 'true';
    }
}
