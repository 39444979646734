import { Controller } from "@hotwired/stimulus"
import moment from 'moment';

export default class ApplicationQuestionTimerController extends Controller {
  connect() {
    this._init();
  }

  _init() {
    const that = this;
    const element = this.element;
    const seconds_left = parseInt(this.element.getAttribute('data-time-left')) + 1;
    const total_seconds_available = this.element.getAttribute('data-time-total');

    this.initQuestionCountdown(that, seconds_left, total_seconds_available)

    if (isDevEnvironment()) {
      console.info(`[DEV] Autosave Question Initialized`);
    }
  }

  initQuestionCountdown(that, seconds_left, total_seconds_available){
    const element = that.element;
    const assessorTestingQuestionId = $(element).closest('form').data('assessor-testing-question');

    Number.prototype.pad = function(size=2) {
      var s = String(this);
      while (s.length < (size || 2)) {s = "0" + s;}
      return s;
    }

    var countDownDate = new Date(moment(new Date()).add(seconds_left, 'seconds').format()).getTime();
    var x = setInterval(function() {

      if($(document).find(`[data-assessor-testing-question="${assessorTestingQuestionId}"]`).length){
        var now = new Date().getTime();
        var distance = countDownDate - now;

        if(Math.floor(distance/1000) <= 0){
          that.timeIsUp(element);
        }

        if (Math.floor(distance/1000) < 0) {
          clearInterval(x);
        } else {
          var days = Math.floor(distance / (1000 * 60 * 60 * 24));
          var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          var seconds = Math.floor((distance % (1000 * 60)) / 1000);

          let display = []
          if(hours+days*24 > 0) { display.push(`${hours+days*24}h`) }
          if(minutes > 0) { display.push(`${minutes}min`) }
          display.push(`${seconds}s`)

          $(element).find('.js-timer').text(display.join(' '));

          $(element).find('.js-timer-bar').css({ 'width': `${(Math.floor(distance/1000)/total_seconds_available) * 100}%` })
        }
      } else {
        clearInterval(x);
      }
    }, 1000);

    if (isDevEnvironment()) {
      console.info(`[DEV] Application Question Timer Initialized`);
    }
  }

  timeIsUp(element) {
    const assessorTestingId = $(element).closest('form').data('assessor-testing');
    const assessorTestingQuestionId = $(element).closest('form').data('assessor-testing-question');

    $.ajax({
      method: "POST",
      url: `/application/test/${assessorTestingId}/questions/${assessorTestingQuestionId}/time-is-up`,
      dataType: "script"
    })
  }
}