
$(document).ready(function(){ 
    
    var $sections = $('.section');
    var prefix = "section-";
    var counter = 1;
    var topOffset = 100;

    // var isElementInViewport = require('../utils/inViewport');

    $sections.each(function() {
        var $section = $(this);
        var viewed = false;

        //Add ID based on prefix and counter.
        $section.prop('id', prefix + counter);

        //Increment counter.
        counter++;

        $(window).on('DOMContentLoaded load resize scroll', function() {
            var sectionTop = $section.offset().top + topOffset;
            var sectionBottom = sectionTop + $section.height();
            var scrollTop = $(this).scrollTop();
            var scrollBottom = scrollTop + $(this).height();

            var inView = (sectionBottom >= scrollTop) && (sectionTop <= scrollBottom);

            if(!viewed && inView) {
                // console.log('-----------------------------');
                // console.log('Section: ', $section);
                // console.log('sectionBottom('+sectionBottom+') >= scrollTop('+scrollTop+')', sectionBottom >= scrollTop);
                // console.log('sectionTop('+sectionTop+') <= scrollBottom('+scrollBottom+')', sectionTop <= scrollBottom);
                $section.addClass('viewed');

                viewed = true;
            }
        });
    });

});