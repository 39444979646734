// app/javascript/controllers/assessor_score_calculator_controller.js

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["radio", "score"]
  static values = {
    maxDemotion: { type: Number, default: 0 },
    minPromotion: { type: Number, default: 0 }
  }

  connect() {
    this.calculateScore()
  }

  calculateScore() {
    console.log("Calculating score")
    const totalFields = this.radioTargets.length / 5 // Assuming 5 options per field
    const selectedValues = this.radioTargets
      .filter(radio => radio.checked)
      .map(radio => parseInt(radio.value))

    if (selectedValues.length === totalFields) {
      const totalScore = selectedValues.reduce((sum, value) => sum + value, 0)
      const maxPossibleScore = totalFields * 5
      const percentageScore = (totalScore / maxPossibleScore) * 100

      this.updateScoreDisplay(percentageScore)
    } else {
      this.scoreTarget.textContent = "Select all ratings to see the score."
      this.resetScoreClasses()
    }
  }

  updateScoreDisplay(percentageScore) {
    this.scoreTarget.textContent = `Score: ${percentageScore.toFixed(0)}%`
    this.resetScoreClasses()

    if(this.maxDemotionValue > 0 || this.minPromotionValue > 0) {
      this.scoreTarget.classList.add("has-text-warning")
    }

    if (this.maxDemotionValue > 0 && percentageScore < this.maxDemotionValue) {
      this.scoreTarget.classList.add("has-text-alert")
    } else if (this.minPromotionValue > 0 && percentageScore >= this.minPromotionValue) {
      this.scoreTarget.classList.add("has-text-success")
    }
    // If neither condition is met, no color class is added
  }

  resetScoreClasses() {
    this.scoreTarget.classList.remove("has-text-alert", "has-text-success")
  }

  radioChanged() {
    console.log("Radio changed")
    this.calculateScore()
  }
}