$(function() {

    var $slider = $('.block-slider-4');
    var $slider2 = $('.block-slider-2');

    if($slider.length)
        $slider.each(function() {
        var $section = $(this);
        var $slideshow = $section.find('.slider-list');
        
        $slideshow.slick({
            arrows: true,
            centerMode: true,
            centerPadding: '120px',
            focusOnSelect: true,
            dots: false,
            // prevArrow:"<button type='button' class='slick-prev pull-left'><img src='./images/chevron-left.svg'></button>",
            // nextArrow:"<button type='button' class='slick-next pull-right'><img src='./images/chevron-right.svg'></button>",
            responsive: [
                {
                  breakpoint: 768,
                  settings: {
                    centerMode: true,
                    centerPadding: '20px',
                    slidesToShow: 2
                  }
                },
                {
                  breakpoint: 540,
                  settings: {
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 1
                  }
                }
              ]
        });
    });




    if($slider2.length)
        $slider2.each(function() {
        var $section = $(this);
        var $slideshow = $section.find('.slider-list');
        
        $slideshow.slick({
            arrows: true,
            centerMode: true,
            centerPadding: '120px',
            focusOnSelect: true,
            dots: false,
            responsive: [
                {
                  breakpoint: 768,
                  settings: {
                    centerMode: true,
                    centerPadding: '20px',
                    slidesToShow: 2
                  }
                },
                {
                  breakpoint: 540,
                  settings: {
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 1
                  }
                }
              ]
        });
    });

});