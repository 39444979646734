import { Controller } from "@hotwired/stimulus"

export default class ScrollableFilters extends Controller {
  static targets = ["input"]

  connect() {
    this._init();
  }

  _init() {
    this.inputTargets.forEach(input => {

      const inputEl = input.querySelector('input');


      if (inputEl.checked) {
        this.element.scrollTo({
          left: input.offsetLeft - 20,
          behavior: 'smooth'
        });
      }

      input.addEventListener('click', () => {
        this.element.scrollTo({
          left: input.offsetLeft - 16,
          behavior: 'smooth'
        });
      });
    });


  }
}
