import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";

export default class SelectController extends Controller {
  connect() {
    this._init();
  }

  _init() {
    const choice = new Choices(this.element, {
      searchEnabled: false,
      itemSelectText: '',
      shouldSort: false,
    });

    if (isDevEnvironment()) {
      if (choice) {
        console.info(`[DEV] Choices initialized for: `, this.element);
      }
      else {
        console.error(`[DEV] Error in initialization of Choices for element: `, this.element);
      }
    }
  }
}
