window.isDevEnvironment = function () {
  let environment = document.body.dataset.environment;

  if (environment && environment == 'development') {
    return true;
  }

  return false;
}

window.getParameterByName = function(name) {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(window.location.href);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}