import { Controller } from "@hotwired/stimulus";

export default class CurrencyInputController extends Controller {
  connect() {
    this._init();
  }

  _init() {   
    const input = this.element.querySelector('input[type="text"]');
    const hiddenInput = this.element.querySelector('input[type="number"]');

    input.addEventListener('input', (event) => {
      const value = this._formatInput(event.target.value, hiddenInput);
      event.target.value = value;
    })

    input.value = this._formatInput(hiddenInput.value, hiddenInput);
  }

  _formatInput(value, hiddenInput) {
    value = value.replace('.', '').replace(',', '').replace(/\D/g, '');
  
    const parsedValue = parseFloat(value);
    if (isNaN(parsedValue)) {
      return '0';
    }

    hiddenInput.value = parsedValue;

    const options = { minimumFractionDigits: 2 };
    const result = new Intl.NumberFormat('en-AE', options).format(
      parsedValue / 100
    );

    return result;
  }
}


