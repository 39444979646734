import { Controller } from "@hotwired/stimulus"
import intlTelInput from 'intl-tel-input';
import utils from 'intl-tel-input/build/js/utils'

export default class PhoneController extends Controller {
  connect() {
    this._init();
  }

  _init() {
    const input = this.element;
    input.removeAttribute('data-controller');
    input.classList.add('is-fullwidth');

    const iti = intlTelInput(input, {
      preferredCountries: ["ae"],
      separateDialCode: true,
      utilsScript: utils,
      hiddenInput: input.getAttribute('data-name')
      //geoIpLookup: function(callback) {
      //  $.get('https://api.ipregistry.co/?key=vojky8euop4w8iyd').always(function(resp) {
      //    console.log(resp);
      //    var countryCode = (resp && resp.location) ? resp.location.country.code : "ae";
      //    callback(countryCode);
      //  });
      //}
      
    });

    console.info(`[DEV] International Telephone Input initialized for: `, this.element);
  }
}
