import { Controller } from "@hotwired/stimulus"

export default class AutosaveQuestionsController extends Controller {
  connect() {
    this._init();
  }

  _init() {
    const that = this;
    const element = this.element;
    const questionId = this.element.getAttribute('data-question');
    const changer = this.element.getAttribute('data-changer');
    let typingTimer;

    element.querySelectorAll('textarea').forEach(input => {
      input.addEventListener("input", () => {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(function(){
          that.saveQuestion(questionId, changer);
        }, 1000);
      });
    });

    element.querySelectorAll('input[type="radio"]').forEach(input => {
      input.addEventListener("change", () => {
        that.saveQuestion(questionId, changer);
      })
    })

    if (isDevEnvironment()) {
      console.info(`[DEV] Autosave Question Initialized`);
    }
  }

  saveQuestion(questionId, changer){
    $('.autosaving').removeClass('is-fadedout');
    let questionWrapper = document.querySelector(`[data-question="${questionId}"]`);
    let answer = $(questionWrapper).find('input[type="radio"]:checked').val();
    let assessor_comment = $(questionWrapper).find('textarea[data-comment="assessor"]').val();
    let admin_comment = $(questionWrapper).find('textarea[data-comment="admin"]').val();
    $.ajax({
      method: "PATCH",
      url: `/${changer}/autosave/question/${questionId}`,
      dataType: "script",
      data: {
        change: {
          assessment_report_id: $('[data-assessment-report-id]').attr('data-assessment-report-id'),
          last_form_instance_at: $('[data-last-form-instance-at]').attr('data-last-form-instance-at')
        },
        report_question: {
          answer: answer,
          admin_comment: admin_comment,
          assessor_comment: assessor_comment
        }
      },
      error: function() {
        dispatchModal(somethingWrongModal, { size: 'small' });
      }
    })
  }
}