import { Controller } from "@hotwired/stimulus";
export default class TextareaController extends Controller {

  connect() {
    this._init();
  }

  _init() {
    const element = this.element;

    if (element.dataset.useGrammarly) {
      element.addEventListener('focus', () => {
        initGrammarly(element);
      });
    }

    element.style.height = "1px";
    element.style.height = (element.scrollHeight) + "px";

    $(element).on('keydown keyup input', (e) => {
      if (element.dataset.readonly === "true") {
        e.preventDefault();
      }
      if (element.dataset.wordCount === "true") {
        this._appendTextAndCountWords();
      }
      element.style.height = "1px";
      element.style.height = (element.scrollHeight) + "px";
    });

    if (isDevEnvironment()) {
      console.info(`[DEV] Textarea Initialized`);
    }
  }

  _appendTextAndCountWords() {
    const element = this.element;
    let span = document.getElementById("wordCount");

    const text = element.value;
    const words = text.trim().split(/\s+/);

    if (!span) {
      span = document.createElement("span");
      span.id = "wordCount";
      element.parentNode.insertBefore(span, element.nextSibling);
    }

    span.textContent = text;
    span.dataset.wordCount = words.length;

    const wordCountText = words.length === 1 ? "1 word" : words.length + " words";
    if(text.trim().length === 0){
      wordCountText = ""
    }
    span.textContent = wordCountText;
  }
}
