import { Controller } from "@hotwired/stimulus";
import JSConfetti from 'js-confetti'

export default class ConfettiController extends Controller {
  connect() {
    this._init();
  }

  _init() {
    const element = this.element;
    this.addConfetti();
  }

  addConfetti() {
    const jsConfetti = new JSConfetti()
    jsConfetti.addConfetti({
      confettiColors: ['#eb5727', '#ffffff'],
      confettiRadius: 6,
      confettiNumber: 1000,
    })
  }
}
