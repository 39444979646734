$(document).on('turbolinks:load', function(){ 

    var $html = $('html');
    var $overlays = $('.page-overlay');
    var $burger = $('.header__menu-trigger .burger');

    $html.removeClass('overlaid');
    // var $components = $overlays.find('.page-overlay-component');

    var events = {
        targeted: 'overlay-component-targeted'
    };
    
    $('[data-overlay-target]').on('click', function(event) {
        event.preventDefault();

        var $trigger = $(this);
        var targetName = $trigger.data('overlay-target');
        var targetSelector = '[data-overlay-trigger="'+ targetName +'"]';
        var $target = $overlays.filter(targetSelector);
        var product = $trigger.data('product');

        if($target && $target.length) {
            $target.trigger(events.targeted, {
                name: targetName,
                component: $target,
                selector: targetSelector,
                product: product
            });
        }
    });

    $overlays.on(events.targeted, function(event, args) {
        var $overlay = $(this);
        var isActive = $overlay.hasClass('active');
        var isMenu = args && args.name == "menu";
        var hasProduct = args && args.product;

        $overlays
            .not($overlay)
            .removeClass('active');

        $html.toggleClass('overlaid', !isActive);
        $overlay.toggleClass('active', !isActive);
        $burger.toggleClass('active', isMenu && !isActive);

        if(hasProduct) {
            var $productField = $overlay.find('#product-type');
            
            if($productField.length) $productField.val(args.product);
        }
    });

});
