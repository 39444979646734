import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default class BarChartController extends Controller {
  connect() {
    this._init();
  }

  _init() {
    // Get the chart element
    let chartElement = this.element;

    // Parse the data from the data attributes
    let clients = JSON.parse(chartElement.dataset.clients);
    let scores = JSON.parse(chartElement.dataset.scores);
    let counts = JSON.parse(chartElement.dataset.counts);

    let aspectRatio = 1.25;

    if (clients.length < 4) {
      aspectRatio = 2
    } else if (clients.length < 2) {
      aspectRatio = 4
    }

    // Create the chart
    let ctx = chartElement.getContext('2d');
    let chart = new Chart(ctx, {
      type: 'bar',
      plugins: [ChartDataLabels],
      data: {
        labels: clients,
        datasets: [{
          label: 'Performance Score',
          data: scores,
          borderColor: '#eb5727',
          backgroundColor: 'rgba(235, 87, 39, 0.2)',
          borderWidth: 1,
          maxBarThickness: 50
        }]
      },
      options: {
        aspectRatio: aspectRatio,
        responsive: true,
        indexAxis: 'y',
        scales: {
          y: {
            beginAtZero: true,
          },
          x: {
            ticks: {
              callback: function(value, index, values) {
                return value + '%';
              }
            }
          }
        },
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'start',
            color: '#eb5727',
            textAlign: 'left',
            font: {
              size: 12,
              weight: 'bold'
            },
            formatter: function(value, context) {
              return parseFloat(value).toFixed(2) + "%"
            }
          },
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                let score = tooltipItem.parsed.x.toFixed(2);
                return 'Performance Score: ' + score + '%';
              },
              afterLabel: function (tooltipItem) {
                return 'Assessments Count: ' + counts[tooltipItem.dataIndex];
              }
            }
          }
        }
      }
    });
  }
}
