window.reportImagesUpload = function(file_field){
  if (file_field.files) {
    var filesAmount = file_field.files.length;
    for (i = 0; i < filesAmount; i++) {
      var reader = new FileReader();
      reader.onload = function(event) {
        var subsectionId = $(file_field).attr('data-subsection-id');
        var uploader = $(file_field).attr('data-uploader');
        uploadImage(subsectionId, event.target.result, uploader);
      };
      image_data = reader.readAsDataURL(file_field.files[i]);
    }
  }
}

function uploadImage(subsectionId, imageBase64, uploader){
  $('.autosaving').removeClass('is-fadedout');
  $.ajax({
    method: "POST",
    url: `/${uploader}/autosave/add-image/${subsectionId}`,
    dataType: "script",
    data: {
      change: {
        assessment_report_id: $('[data-assessment-report-id]').attr('data-assessment-report-id'),
        last_form_instance_at: $('[data-last-form-instance-at]').attr('data-last-form-instance-at')
      },
      report_image: {
        image: imageBase64
      }
    }
  })
}

function addImages(wrapper) {
  $(wrapper).find('input[type="file"]')[0].click();
}

window.removeReportImage = function(button) {
  $('.autosaving').removeClass('is-fadedout');
  var eraser = $(button).attr('data-eraser');
  var reportImageId = $(button).closest('[data-report-image]').attr('data-report-image');
  $.ajax({
    method: "POST",
    url: `/${eraser}/autosave/remove-image/${reportImageId}`,
    dataType: "script",
    data: {
      change: {
        assessment_report_id: $('[data-assessment-report-id]').attr('data-assessment-report-id'),
        last_form_instance_at: $('[data-last-form-instance-at]').attr('data-last-form-instance-at')
      }
    }
  })
}