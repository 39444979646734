import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';

export default class extends Controller {
  connect() {
    this._init();
    window.addEventListener('resize', this._updateAspectRatio.bind(this));
  }

  disconnect() {
    window.removeEventListener('resize', this._updateAspectRatio.bind(this));
  }

  _init() {
    // Get the chart element
    let chartElement = this.element;

    // Parse the data from the data attributes
    let labels = JSON.parse(chartElement.dataset.labels).map(label => new Date(label));
    let data = JSON.parse(chartElement.dataset.data);
    let chartType = chartElement.dataset.type;
    let tooltips = chartElement.dataset.tooltips ? JSON.parse(chartElement.dataset.tooltips) : null;

    // Determine the initial aspect ratio based on the window size
    let aspectRatio = window.innerWidth < 768 ? 1.5 : 16/6;

    // Create the chart
    let ctx = chartElement.getContext('2d');
    this.chart = new Chart(ctx, {
      type: chartType,
      data: {
        labels: labels,
        datasets: [{
          label: 'Performance Score',
          data: data,
          borderColor: '#eb5727',
          backgroundColor: 'rgba(235, 87, 39, 0.2)',
          //tension: 0.2,
          borderWidth: 1.5,
          borerJointStyle: 'round',
          pointBackgroundColor: '#eb5727',
          pointRadius: 1.5,
          pointHitRadius: 10,
        }]
      },
      options: {
        responsive: true,
        aspectRatio: aspectRatio,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              title: function(tooltipItem) {
                let date = new Date(tooltipItem[0].label);
                let formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
                return formattedDate;
              },
              label: function(tooltipItem) {
                let index = tooltipItem.dataIndex;
                let tooltip = tooltips ? 'Assessment: ' + tooltips[index] + ', ' : '';
                return tooltip + 'Score: ' + tooltipItem.parsed.y + '%';
              }
            }
          }
        },
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'month',
              displayFormats: {
                month: 'MM/yyyy'
              }
            }
          },
          y: {
            beginAtZero: true,
            ticks: {
              callback: function(value, index, values) {
                return value + '%';
              }
            }
          }
        }
      }
    });
  }

  _updateAspectRatio() {
    // Update the aspect ratio based on the window size
    this.chart.options.aspectRatio = window.innerWidth < 768 ? 1.25 : 16/6;
    this.chart.update();
  }
}
