import { Controller } from "@hotwired/stimulus"

export default class AutosaveSectionsController extends Controller {
  connect() {
    this._init();
  }

  _init() {
    const that = this;
    const element = this.element;
    const sectionId = this.element.getAttribute('data-section');
    const changer = this.element.getAttribute('data-changer');
    let typingTimer;

    element.querySelectorAll('[data-field="location"]').forEach(input => {
      input.addEventListener("input", () => {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(function(){
          that.saveSection(sectionId, changer);
        }, 1000);
      });
    });

    element.querySelectorAll('[data-field="date"], [data-field="time"]').forEach(input => {
      input.addEventListener("change", () => {
        that.saveSection(sectionId, changer);
      })
    })

    if (isDevEnvironment()) {
      console.info(`[DEV] Autosave Section Initialized`);
    }
  }

  saveSection(sectionId, changer){
    $('.autosaving').removeClass('is-fadedout');
    let sectionWrapper = document.querySelector(`[data-section="${sectionId}"]`);
    let date = $(sectionWrapper).find('[data-field="date"]').val();
    let time = $(sectionWrapper).find('[data-field="time"]').val();
    let location = $(sectionWrapper).find('[data-field="location"]').val();

    $.ajax({
      method: "PATCH",
      url: `/${changer}/autosave/section/${sectionId}`,
      dataType: "script",
      data: {
        change: {
          assessment_report_id: $('[data-assessment-report-id]').attr('data-assessment-report-id'),
          last_form_instance_at: $('[data-last-form-instance-at]').attr('data-last-form-instance-at')
        },
        report_section: {
          location: location,
          date: date,
          time: time
        }
      },
      error: function() {
        dispatchModal(somethingWrongModal, { size: 'small' });
      }
    })
  }
}
