import $ from 'jquery';

$("form.form").validate({
	rules: {
		name: {
			required: true,
		},
        company: {
            required: true
        },
        email: {
            required: true,
            email: true
        }
	}
});


$(function(){
    var $codeDropdown = $(".code-dropdown");
    var $val = $("#countryCode");

    //Assign selected value to hidden input on page load 
    $val.val($codeDropdown.children('.init').find(".code-value").text());

    if($codeDropdown.length) {

        $codeDropdown.on("click", ".init", function() {
            $(this).closest("ul").children('li:not(.init)').toggle();

            if ($codeDropdown.hasClass("active"))
                $codeDropdown.removeClass("active");
            else
                $codeDropdown.addClass("active");

        });
        
        var allOptions = $codeDropdown.children('li:not(.init)');
        $codeDropdown.on("click", "li:not(.init)", function() {
            allOptions.removeClass('selected');
            $(this).addClass('selected');
            $("ul").children('.init').html($(this).html());
            allOptions.toggle();

            //Assign selected value to hidden input on option select.
            $val.val($codeDropdown.children('.init').find(".code-value").text());
            $codeDropdown.removeClass("active");            
        });

        $(document).on('click', function(e) {
            if (!$(e.target).closest($codeDropdown).length) {
                $codeDropdown.children('li:not(.init)').hide();
                $codeDropdown.removeClass("active");
            }
        });
    }

})

$(function () {

    $("#contactForm").submit(function () {

        event.preventDefault();

        var $form = $(this);

        if (!$form.valid()) {
            return;
        }

        var recaptchaKey = $form.data("recaptchakey");

        grecaptcha.ready(function () {

            grecaptcha.execute(recaptchaKey, { action: 'contactForm' })
                .then(function (token) {

                    $form.find("input[name='gRecaptchaResponse']").val(token);

                    // submit form now
                    $form.unbind('submit').submit();

                });

        });
    });

})
