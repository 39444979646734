import { Controller } from "@hotwired/stimulus";
import WaveSurfer from 'wavesurfer.js';

export default class AudioClipController extends Controller {
  static targets = ["playPauseButton", "speedButtons", "clipDuration"];

  connect() {
    if(this.element.dataset.initialized != 'true') {
      this._init();
      this.setInitialButtonState();
    }
  }

  _init() {
    const audioClipSrc = this.element.dataset.audioClipSrc;
    const options = {
      container: this.element,
      splitChannels: false,
      normalize: false,
      waveColor: "#eb5727",
      progressColor: "#b8b8b8",
      cursorColor: "#000",
      cursorWidth: 1,
      barWidth: 10,
      barGap: 4,
      barRadius: 30,
      barAlign: "bottom",
      minPxPerSec: 1,
      fillParent: true,
      url: audioClipSrc,
      mediaControls: false,
      autoplay: false,
      interact: true,
      dragToSeek: false,
      hideScrollbar: false,
      audioRate: 1,
      autoScroll: true,
      autoCenter: true,
      sampleRate: 8000
    };

    this.wavesurfer = WaveSurfer.create(options);
    this.wavesurfer.load(audioClipSrc);

    this.wavesurfer.on('ready', () => {
      this.updateDurationDisplay();
    });

    this.wavesurfer.on('audioprocess', () => {
      this.updateDurationDisplay();
    });

    this.wavesurfer.on('play', () => this.stopOtherPlayers());
    this.wavesurfer.on('finish', () => this.updateButtonState());

    this.element.dataset.initialized = 'true';
  }

  togglePlayback() {
    if (this.wavesurfer.isPlaying()) {
      this.wavesurfer.pause();
    } else {
      this.wavesurfer.play();
    }
    this.updateButtonState();
  }

  updateButtonState() {
    const playButton = this.playPauseButtonTarget;
    const playSVG = playButton.querySelector('[data-status="play"]');
    const pauseSVG = playButton.querySelector('[data-status="pause"]');
    
    if (this.wavesurfer.isPlaying()) {
      playSVG.style.display = 'none';
      pauseSVG.style.display = 'block';
    } else {
      playSVG.style.display = 'block';
      pauseSVG.style.display = 'none';
    }
  }

  stopOtherPlayers() {
    const activePlayers = document.querySelectorAll('[data-controller="audio-clip-player"]');
    activePlayers.forEach(playerElement => {
      const controller = this.application.getControllerForElementAndIdentifier(playerElement, "audio-clip-player");
      console.log(controller);
      if (controller && controller !== this && controller.wavesurfer && controller.wavesurfer.isPlaying()) {
        controller.wavesurfer.pause();
        controller.updateButtonState();
      }
    });
  }

  changeSpeed(event) {
    const speed = parseFloat(event.target.dataset.speed);
    this.wavesurfer.setPlaybackRate(speed);
    this.updateButtonState();

    this.speedButtonsTargets.forEach(button => button.classList.remove('is-selected'));
    event.target.classList.add('is-selected');
  }

  async deleteAudio() {
    const confirmDelete = await new Promise(resolve => {
      window.askQuestion(
        I18n.t('are_you_sure'),
        I18n.t('yes'),
        I18n.t('no'),
        () => resolve(true),
        () => resolve(false)
      );
    });

    if (confirmDelete) {
      $('.autosaving').removeClass('is-fadedout');
      try {
        const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        const reportIdElement = document.querySelector('[data-assessment-report-id]');
        const lastFormInstanceElement = document.querySelector('[data-last-form-instance-at]');

        if (!reportIdElement || !lastFormInstanceElement) {
          console.error('Unable to find elements with data attributes');
          return;
        }

        const reportId = reportIdElement.dataset.assessmentReportId;
        const lastFormInstance = lastFormInstanceElement.dataset.lastFormInstanceAt;
        const reportAudioClipId = this.element.dataset.reportAudioClipId;
        const changer = this.element.dataset.changer;

        const requestData = {
          change: {
            assessment_report_id: reportId,
            last_form_instance_at: lastFormInstance
          }
        };

        fetch(`/${changer}/autosave/remove-audio/${reportAudioClipId}`, {
          method: 'POST',
          headers: {
            'X-CSRF-Token': token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestData)
        })
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to upload audio');
          }
          return response.text();
        })
        .then(scriptText => {
          eval(scriptText);
        })
        .catch(error => {
          console.error('Error uploading audio: ', error);
        });
      } catch (error) {
        console.error('Error uploading audio: ', error);
      }
    }
  }

  setInitialButtonState() {
    const playButton = this.playPauseButtonTarget;
    const playSVG = playButton.querySelector('[data-status="play"]');
    const pauseSVG = playButton.querySelector('[data-status="pause"]');
    
    if (this.wavesurfer.isPlaying()) {
      playSVG.style.display = 'none';
      pauseSVG.style.display = 'block';
    } else {
      playSVG.style.display = 'block';
      pauseSVG.style.display = 'none';
    }
  }

  updateDurationDisplay() {
    const currentTime = this.wavesurfer.getCurrentTime();
    const totalDuration = this.wavesurfer.getDuration();
    this.clipDurationTarget.textContent = `${this.formatTime(currentTime)} / ${this.formatTime(totalDuration)}`;
  }

  formatTime(time) {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  }
}

