import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js';

export default class InfoTooltipController extends Controller {
  connect() {
    this._init();
  }

  _init() {
    if($(this.element).attr('data-tooltip')){
      tippy([this.element], {
  	    placement: 'bottom',
  	    animation: 'scale-subtle',
        allowHTML: true,
        interactive: true,
        trigger: "mouseenter focus focusin click",
        theme: 'material',
  	    content: (reference) => reference.getAttribute('data-tooltip')
      })
      console.info(`[DEV] Tippy initialized for: `, this.element);
    }
  }
}
