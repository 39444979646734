$(document).on('turbolinks:load', function(){
    var $scroll = $(".scrolldown");

    if($scroll.length) {
        $scroll.on("click", function() {
            $("html, body").animate({
                    scrollTop: $(".section--banner").next().offset().top - ($("header.header").height() + 50)
            }, 800);
        });
    }
})
