import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js';

export default class TooltipController extends Controller {
  connect() {
    this._init();
  }

  _init() {
    let placement = 'top'

    if($(this.element).attr('data-tooltip-placement')){
      placement = $(this.element).attr('data-tooltip-placement');
    }

    if($(this.element).attr('data-tooltip-disabled') != 'true' && $(this.element).attr('data-tooltip')){
      tippy([this.element], {
  	    placement: placement,
  	    animation: 'scale-subtle',
  	    content: (reference) => reference.getAttribute('data-tooltip')
      })
      console.info(`[DEV] Tippy initialized for: `, this.element);
    }

  }
}
