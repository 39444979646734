import { Controller } from "@hotwired/stimulus"

export default class CollapseRowsController extends Controller {
  connect() {
    this._init();
  }

  _init() {
    const element = this.element;
    const showFirst = parseInt(this.element.getAttribute('data-show-first'));

    const rows = $(element).find('tr');

    if(rows.length > showFirst) {
      rows.slice(showFirst).css('display', 'none');
      $(element).closest('table').css('margin-bottom', '0');
      $(element).closest('table').after(`<button data-show-more-rows class='button is-small is-lighter is-fullwidth'>show +${rows.length - showFirst} more</button>`);

      $('[data-show-more-rows]').on('click', function(){
        $(this).prev('table').find('tr').show();
        $(this).remove();
      })
    }
  }

}