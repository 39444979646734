
$(document).on('turbolinks:load', function(){ 
    
    var $html = $('html');

    var $menu = $('.overlay-menu');
    var $items = $menu.find('[data-target]');

    $items.on('click', function(event) {
        event.preventDefault();

        var $item = $(this);
        var targetSelector = $item.attr('data-target');
        var $target = $(targetSelector);

        if($target.length) {
            $html.animate({
                scrollTop: $target.offset().top
            });

            //Close menu.
            $menu.trigger('overlay-component-targeted');
        }
    });

});
